.form-container-viewer
    background: #fff
    padding: 16px

.content
  height: calc(100vh - 50px)
  display: flex
  flex-direction: column

.content-hide
  display: none

.viewports-layout
  width: 100%
  height: 100%

.viewport-container
  display: flex
  width: 100%
  height: 100%
  position: absolute
  background: #000
  border: 1px solid #323237

.viewport-container.active
  border: 1px solid #e51b48

#root
  height: 100%

.top-menu
  text-align: center

.viewer-menu-container
  background-color: #000
  display: flex
  flex-direction: row
  height: 100%
  flex: 1

div#viewer
  position: relative
  flex: 1
  margin: 40px

.mouse-function
  -webkit-flex-direction: column
  flex-direction: column
  display: flex

.mouse-function span, .mouse-function label
  color: #fff

.mouse-function input, .custom-study input
  max-width: 140px

.sync-checkbox
  position: absolute
  right: 3px