.card
  min-width: 275px

.key-column
  color: #666

.description-key
  margin: 0

.desription-container
  margin: 10px 0px,
  padding: 10px 0px,
  border-bottom: 1px solid #CCC,

.study-status
  text-transform: capitalize

.weight-medium
  font-weight: 600

.info-container
  line-height: 1.5
  display: flex
  justify-content: space-between
  padding: 10px 0
  border-bottom: 1px solid #CCC
  &:last-child
    border: none

  p 
      margin: 0

.info-description
  font-size: 16px
  font-family: "Roboto",sans-serif
  font-weight: 400
  line-height: 1.5


.pass
  width: 61px
  height: 24px
  background-color: #2CA895
  color: #FFFFFF
  border-radius: 2px
  display: flex
  justify-content: center
  align-items: center

.no_qc
  width: 61px
  height: 24px
  background-color: #F5C434
  color: #FFFFFF
  border-radius: 2px
  display: flex
  justify-content: center
  align-items: center

.error
  background-color: #9B1A06
  color: #FFFFFF
  width: 61px
  height: 24px
  border-radius: 2px
  display: flex
  justify-content: center
  align-items: center

  
.key-total
  font-weight: 500,

.total
  width: 61px
  height: 24px
  background-color: #292929
  color: #FFFFFF
  border-radius: 2px
  display: flex
  justify-content: center
  align-items: center
  

.card-acquisition-container
  margin-bottom: 20px

.progress-bar
  width: 100%

.progress-container-info
  display: flex
  flex-flow: row
  justify-content: space-between
  margin-top: 4px


.sites-info
  display: flex
  align-items: center
  justify-content: space-around

.per-site
  width: 70px
  height: auto
  text-align: center
  margin-left: 10px