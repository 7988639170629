.container-icons
    position: relative
    min-width: 80px
    display: flex
    align-items: center
    justify-content: center
    padding: 16px
    max-width: 80px
    max-height: 80px

.active
    background-color: #f2f2f2
    border-radius: 5px

.study-top-btn
    position: relative
    display: flex
    flex-direction: column
    align-items: center
    height: 100%
    cursor: pointer

    h5
        text-align: center
        align-self: flex-end
        padding: 0
        padding-top: 5px
        font-family: "Roboto",sans-serif
        position: relative
        bottom: 0
        color: #666
        user-select: none

.top-btn-drop-down
    cursor: pointer

.study-top-dropdown
    display: flex
    align-items: center
    margin-left: 5px

.container-mouse
    position: absolute
    bottom: 0
    right: 0

.selected-mouse
    margin-right: -3px
    margin-bottom: -5px

.dropdown-container 
    position: absolute
    margin: 0
    top: 100%
    display: inline-block
    text-align: center
    cursor: pointer
    min-width: 100%
    left: 0
    color: #d0d0d0
    font-size: 0.8em
    font-weight: 400
    background-color: #333
    border-radius: 5px
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1)
    z-index: 10