// //***************************
// // TABLE
// //***************************

.root
    margin: auto

.body-row
    cursor: pointer

.body-cell
    span
        font-size: 1rem
    .active 
        align-items: center
        display: inline-flex
        color: white
        background-color: transparent
        text-transform: capitalize
        font-size: 1rem
        padding-left: 0
        height: 2em
        color: #2CA895
    .open
        color: #3C73B8
        text-transform: capitalize
    .rejected
        color: #9B1A06
        text-transform: capitalize
    .done
        color: #2CA895
        text-transform: capitalize
        
