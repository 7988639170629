.dashboard-side-panel
    background: #2CA895
    background-color: #2CA895
    height: 100%
    min-height: 100vh
    border-right: 1px solid #DEDEDE
    padding: 3.5rem 2.5rem
    .tab-link
        padding: 0.625rem 0rem

        a, .logout
            color: white
            display: flex

            i 
                font-size: 1rem
                display: flex
                align-items: center
                margin-left: 5px


            &:before
                content: '\00a0'
                padding-right: 0.5rem

        &.is-active
            font-weight: bold


.project-side-panel
    background-color: #ffffff
    width: 100%
    height: 100%
    border-right: 1px solid #DEDEDE
    display: block
    padding: 3.5rem 2.5rem
    min-height: 100vh



    a,.logout
        color: #292929
        display: block

    .is-active a
        margin: 0 -2.5rem
        padding-left: 2.5rem
        color: #2CA895
    .tab-link
        padding: 0.625rem 0rem

    
    .sidepanel-category
        color: #8F9194
        font-size: 12px
        text-transform: uppercase
        margin-top: 10px

        &:after
            background-color: #ccc
            content: ""
            display: inline-block
            height: 1px
            position: relative
            vertical-align: middle
            width: 70%
            margin-left: 10px

    .dashboard-link
      display: block
      padding-top: 1rem
      text-align: center
    
      a
        color: #279181
        opacity: 0.7
        
        &:before
          text-decoration: none
          content: "\003C"
          transform: scale(1.6)
          padding-right: 0.5rem
        
        &:hover
          opacity: 1

.side-menu
    padding-top: 1rem
    a, .logout
        cursor: pointer

    li
        padding: 0.25rem 0rem

    .logout
        border: none
        background: transparent
        padding: 0.625rem 0rem
        font-size: 16px