.loadercontainer
    width: 100%
    height: 100%
    min-height: 100vh
    display: flex
    justify-content: center
    align-items: center

.loadercontainer-hide
    display: none

.children-container-hide
    display: none

.children-container
    width: 100%
    opacity: 1
    animation: fadeIn linear 0.3s

.hideloader
    display: none

#loader-container
    max-width: 500px
    height: auto
