.button-menu
    background: transparent
    padding: 0px
    border-radius: 5px
    font-size: 17px
    margin-left: 5px
    height: 32px
    min-width: 40px

.dot-image
    height: 25px
    width: auto