.root
    width: 100%
  
.title
    margin-bottom: 8px

.row-img
    height: 25px
    width: auto

.row-button
    background: transparent
    border: 1px solid black
    padding: 5px
    cursor: pointer

.icon-card
    padding-bottom: 16px

.icon-description-container
    margin-top: 24px
    margin-bottom: 24px

.icon-description-container-row
    display: flex
    justify-content: space-evenly
    align-items: center
    height: 100%


.icon-description-container-cell
    display: flex
    align-items: center
    line-height: 25px
    height: 25px

.icon-img
    margin-right: 5px