.view
    background: transparent
    padding: 5px 10px
    border: 1px solid #666666
    border-radius: 5px
    font-size: 14px
    margin-left: 5px
    color: #666666
    cursor: pointer

    i
        font-size: 16px
        margin-right: 0
        vertical-align: middle