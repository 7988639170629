.dashboard-link
    display: block
    padding-bottom: 1rem
    a
      color: #279181
      opacity: 0.7
      &:before
        text-decoration: none
        content: "\003C"
        transform: scale(1.6)
        padding-right: 0.5rem
      &:hover
        opacity: 1
