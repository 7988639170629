.container-project-list-page
    padding: 16px
    padding-top: 3rem

.paragraph 
    margin-bottom: "8px",
    margin-top: "8px"

// //***************************
// // CREATE STUDY BUTTON
// //***************************


.create-study-button-row
    padding-bottom: 1.5rem
    padding-top: 1rem
    display: flex
    justify-content: space-between

.btn
    border: none
    border-radius: 2px
    cursor: pointer
    min-height: 44px
    align-items: center
    padding: 4px 16px
    box-shadow: 0px 1px 2px 0px rgb(41 41 41 / 60%)
    font-size: 16px
    font-weight: 500
    color: #ffffff
    text-transform: uppercase

    &.primary
        background-color: #2CA895
        &:hover
            background-color: darken(#2CA895, 10%)

