@import url('https://fonts.googleapis.com/css2?family=Mulish:wght@300;700&display=swap')

.viewer-form-container
    padding: 10px
    padding-bottom: 50px
    height: calc(100vh - 100px)

.btn-group
    display: flex
    justify-content: space-between
    @media (max-width: 1200px)
        display: block

.title-container
    padding-left: 0.75rem
    padding-bottom: 1rem
    border-bottom: 1px solid #8F9194
    color: #292929

.title-container .info
    min-height: 30px
    display: flex
    align-items: space-between
    flex-wrap: wrap
    gap: 10px

    h5
        font-size: 1.1rem
        font-family: 'Mulish', sans-serif
        font-weight: 300
        margin: 0
        padding: 0

    h4
        padding: 2px 0 0 0
        font-family: 'Mulish', sans-serif
        font-weight: 700
        margin: 0

.form-viewer
    padding-top: 1rem
    height:100%
    overflow-y: scroll

    h4
        font-family: "Roboto",sans-serif
        font-weight: 500
        color: #292929
        font-size: 14px

.form-viewer::-webkit-scrollbar 
    display: none

.error
    border-color: red

.helper-text
    color: red

.select-inner
    color: #666666
    width: 100%

.select:not(.is-multiple):not(.is-loading)::after
    border-color: #3C73B8

.btn-viewer-form
    font-family: "Roboto", sans-serif, "Segoe UI", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", "Helvetica", "Arial"
    border: none
    border-radius: 2px
    display: flex
    align-content: center
    width:50%
    min-width:70px
    max-width: 120px
    cursor: pointer

    min-height: 44px
    -webkit-justify-content: center
    justify-content: center
    align-items: center
    padding: 4px 16px
    text-align: center

    -webkit-box-shadow: 0px 1px 2px 0px transparentize(#292929, 0.4)
    -moz-box-shadow: 0px 1px 2px 0px transparentize(#292929, 0.4)
    box-shadow: 0px 1px 2px 0px transparentize(#292929, 0.4)
    transition: background-color 0.3s ease
    font-size: 16px
    font-weight: 500
    color: #ffffff
    text-transform: uppercase

    &.primary
        background-color: #2CA895
        &:hover
            background-color: darken(#2CA895, 10%)

    &.secondary
        background-color: #3C73B8
        &:hover
            background-color: darken(#3C73B8, 10%)

    &.tertiary
        background-color: #666666
        &:hover
            background-color: darken(#666666, 10%)
            color: #ffffff

    &.quaternary
        background-color: darken(#f2f2f2, 10%)
        color: #292929
        &:hover
            background-color: #f2f2f2

    &.denial
        background-color: #9B1A06
        &:hover
            background-color: darken(#9B1A06, 10%)

    &.inactive
        opacity: 0.4
        cursor: not-allowed

    &.white
        box-shadow: none
        color: #292929
        min-width: auto

    @media screen and (max-width: 1200px)
        width:100%
        margin-bottom: 1rem
        max-width: none

