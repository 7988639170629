.title
  padding-bottom: 8px

.paragraph
  padding-bottom: 8px

.title-description
  padding: 16px

.cards-left-container
  min-width: 50%
  margin-bottom: 32px !important
  padding-left: 16px
  padding-right: 16px
  flex-basis: auto !important

.button-right-container
  display: flex
  justify-content: flex-end
  margin: 8px 0 0
  padding: 8px 0 0

.columns-container
  display: flex
  padding-left: 16px
  padding-right: 16px

.container-study-overview-page
  display: flex
  flex-direction: column
  min-width: 50%

.ghost-btn
  cursor: pointer
  background: #ffffff
  padding: 8px
  border: 1px solid #292929
  border-radius: 2px
  font-size: 12px
  font-weight: 500
  color: #292929
  min-height: 32px
  vertical-align: middle
  line-height: 18px

  &:hover
    cursor: pointer

  i
    font-size: 18px
    vertical-align: middle
    margin-top: -2px
    margin-right: 4px