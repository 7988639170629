.top-menu
    min-height: 100px
    background-color: #fff
    border-bottom: 1px solid #e51b48
    display: flex
    align-items: center

    .top-menu-history
        display: flex
        min-width: 80px
        max-height: 80px
        justify-content: center
        align-items: center
        text-align: center
        border-right: 1px solid #8F9194
        border-left: 1px solid #8F9194
        padding: 16px

        div
            display: flex
            flex-direction: column
            text-align: center
            justify-content: center
            width: 80px
            height: 80px
            color: #666666

            &:hover
                background-color: #f2f2f2
                border-radius: 5px

            svg
                font-size: 30px

            span
                font-family: "Roboto",sans-serif
                font-weight: 500
                user-select: none


    .card-top-btn
        display: flex
        align-items: center
        justify-content: flex-start
        margin-left: 10px

        & div:hover
                background-color: #f2f2f2
                border-radius: 5px
